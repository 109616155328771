import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';
import { Helmet } from "react-helmet"
 
 
export default function FeatureNews_Home() {

    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
   
    const jsonUrlBoxing = variables.basename_url +'updates/index/Athlethixnet_HomeEn_TrendingNews.json';
    
      async function refda() {  
        setNisLoading(true);
        Get_Data_Boxing();
       
      }
   
   
      useEffect(() => {
      
        setNisLoading(true);
   
        refda();
           },[] )



           const [FeatureArray, setFeatureArray] = React.useState([]);

           async function Get_Data_Boxing(){
          
            FeatureArray.splice(0);  //to clear All data in your page
         
            setNisLoading(true);
         
            axios.get(jsonUrlBoxing)  
         
                  .then(function (response) {
                  setNisLoading( false);
         
                    console.log(response.data);
                
                   
              
         
                  for (let i = 6; i <= 8 && i < response.data.length; i++) {
                  
                    FeatureArray.push({
                      Title: response.data[i].Title,
                     news_date:response.data[i].news_date,
                     Image: response.data[i].Image,
                     link:response.data[i].link,
                     Description:response.data[i].Description   ,
                     Category:response.data[i].Category
        
                   })
                   }
             })      
            }
         
   
     
            return (
          <>
 
 <div className="feature-news-box mt-40 ">
        <div className="section-title mb-3">
          <h3 className="title">Feature News</h3>
        </div>
       
 
              <div className="   ">
         <div className='row'> 
              {FeatureArray.map(da=>     
  
   
  <div className='col-lg-4 col-sm-12 ' key= {da.Title}  >   
 

 
<div  className='   ml-2  '>
<div className="feature-news-item   ">
                  <div  className="feature-news-thumb">
                  <a href={da.link}> 
  <img class="card-img-top  rounded" style={{ minHeight:'217px',maxHeight:'217px'}} src={da.Image} alt="Card image cap"/>
  </a>
                    <div className="meta-categores">
                      <span>{da.Category}</span>
                    </div>
                  </div>
                  <div className="feature-news-content">
                  <a href={da.link}> 
                    <p style={{ minHeight:'80px',maxHeight:'80px'}} >{da.Title} </p>
                    </a>
                  </div>
                  
                </div>
          
             
                 
</div>
 
</div>
)}
        </div>

           

              </div>


      </div>
       
    
    <br></br>
    <br></br>
    <br></br>  


    

  {/* break */}
 
 
         </>
        );
    }


  
