import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';


 
 
export default function OurLatest_News() {
 

  const TrendingUrl2 =variables.basename_url +'updates/index/Athlethixnet_HomeEn_TrendingNews.json';
  


    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
   
  
    async function refda() {  

         
      
        setNisLoading(true);
     
        Get_Data();
       
      }

      const [JsonOurLatestArray, setJsonOurLatestArray] = React.useState([]);
      const [JsonOurLatestArray2, setJsonOurLatestArray2] = React.useState([]);
      const [JsonOurLatestArray3, setJsonOurLatestArray3] = React.useState([]);


      async function Get_Data(){
     
        JsonOurLatestArray.splice(0);  //to clear All data in your page
  
       setNisLoading(true);
    

       axios.get(TrendingUrl2)

   

             .then(function (response) {
             setNisLoading( false);
  
            
           
              
         
  
             for ( let i = 1; i <= 1 && i < response.data.length; i++) {
             
              
              console.log('laith');
              console.log(response.data);
              JsonOurLatestArray.push({
           
                  Image: response.data[i].Image,
                     news_title: response.data[i].news_title,
                     Description: response.data[i].Description
                     .replace("MENAFN",variables.domain)
                     .replace("</p>",""),
                     link:response.data[i].link,     
                            
news_date:response.data[i].news_date.split(" ")[0],
 
Category:response.data[i].Category,
               
              })
              }

              for ( let i = 2; i < 3 && i < response.data.length; i++) {
             
              
                console.log('laith');
                console.log(response.data);
                JsonOurLatestArray2.push({
             
                    Image: response.data[i].Image,
                       news_title: response.data[i].news_title,
                       Description: response.data[i].Description.replace("MENAFN",variables.domain).replace("</p>",""),
                       link:response.data[i].link,     
                              
  news_date:response.data[i].news_date.split(" ")[0],
   
  Category:response.data[i].Category,
                 
                })
                }
                for ( let i = 3; i < 4 && i < response.data.length; i++) {
             
              
                  console.log('laith');
                  console.log(response.data);
                  JsonOurLatestArray3.push({
               
                      Image: response.data[i].Image,
                         news_title: response.data[i].news_title,
                         Description: response.data[i].Description.replace("MENAFN",variables.domain).replace("</p>",""),
                         link:response.data[i].link,     
                                
    news_date:response.data[i].news_date.split(" ")[0],
     
    Category:response.data[i].Category,
                   
                  })
                  }
        })      
       }
         
 
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )

             
     
 

            return (
          <>
 


 <section className=" ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
           {/* <div className="section-title">
                <h3 className="title float-right pb-2">عن اخر الاخبار</h3>
              </div>   */}
            </div>
          </div>



          <div className="row">
            <div className="col-lg-4">
            {JsonOurLatestArray.map(da=>     
  
  <div key= {da.news_title}  >   
  
   
              <div className="trending-news-item mb-30">
                <div className="trending-news-thumb">
                  <a href={da.link}>
                  <img src={da.Image} alt="trending" />
                  </a>
                </div>
                <div className="trending-news-content">
                  <div className="post-meta">
                    <div className="meta-categories">
                      <a href="#">{da.Category}</a>
                    </div>
                    <div className="meta-date">
                      <span>{da.news_date}</span>
                    </div>
                  </div>
                  <h3 className="title"><a href={da.link}>{da.news_title}</a></h3>
                  <p className="text"> {da.Description}</p>
                </div>
              </div>
            </div>
           
             

           )}
           </div>
           <div className="col-lg-4">
            {JsonOurLatestArray2.map(da=>     
  
  <div key= {da.news_title}  >   
  
   
              <div className="trending-news-item mb-30">
                <div className="trending-news-thumb">
                <a href={da.link}>
                  <img src={da.Image} alt="trending" />
                  </a>
                </div>
                <div className="trending-news-content">
                  <div className="post-meta">
                    <div className="meta-categories">
                      <a href="#">{da.Category}</a>
                    </div>
                    <div className="meta-date">
                      <span>{da.news_date}</span>
                    </div>
                  </div>
                  <h3 className="title"><a href={da.link}>{da.news_title}</a></h3>
                  <p className="text"> {da.Description}</p>
                </div>
              </div>
            </div>
           
             

           )}
           </div>
           <div className="col-lg-4">
            {JsonOurLatestArray3.map(da=>     
  
  <div key= {da.news_title}  >   
  
   
              <div className="trending-news-item mb-30">
                <div className="trending-news-thumb">
                <a href={da.link}>
                  <img src={da.Image} alt="trending" />
                  </a>                </div>
                <div className="trending-news-content">
                  <div className="post-meta">
                    <div className="meta-categories">
                      <a href="#">{da.Category}</a>
                    </div>
                    <div className="meta-date">
                      <span>{da.news_date}</span>
                    </div>
                  </div>
                  <h3 className="title"><a href={da.link}>{da.news_title}</a></h3>
                  <p className="text"> {da.Description}</p>
                </div>
              </div>
            </div>
           
             

           )}
           </div>

          </div>
        </div>
      </section>
         </>
        );
    }


  
