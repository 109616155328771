import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';

import MostViewed from './MostViwed.js';
import { data } from 'jquery';
 
 
export default function Football() {

  const jsonUrl = variables.basename_url +'updates/index/Athlethixnet_HomeEn_Football.json';

 
 
    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
     
     
  
      async function refda() {  
          //  To Show or Hidden Header And Footer
          document.getElementById('English_Header').hidden = false;
          document.getElementById('English_Footer').hidden = false;
          //
        setNisLoading(true);
     
        Get_Data();
       
      }

      const [JsonArray, setJsonArray] = React.useState([]);
 
      async function Get_Data(){
     
        JsonArray.splice(0);  //to clear All data in your page
  
       setNisLoading(true);
    
       axios.get(jsonUrl)  

             .then(function (response) {
             setNisLoading( false);
  
               console.log(response.data);
           
              
         
console.log(response.data);
  
             for (let i in response.data) {
             
              JsonArray.push({
            
                news_title: response.data[i].news_title,
                news_date:response.data[i].news_date,
                Image: response.data[i].Image,
                link:response.data[i].link,
                Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  ,

               Category:response.data[i].Category,
              })
              }
        })      
       }
         

 





 


      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )





            return (
          <>


 


 

 <div className='backgroundcolor'> 
 


 <section className="all-post-style-2-area">
       
        <div className="container custom-container">
             
        <div className='    feature-news-content   mt-3 mb-3  '>

<p className='TitleSectionFont  p-1  bg-white'>  Welcome to AthletixNet Football! Get the latest football news, updates on the Premier League, UEFA Champions League (UCL), La Liga, Serie A, and more. Enjoy match highlights and expert analysis to stay informed about your favorite teams and all the exciting action on the field.</p>
</div>


      
          <div className="row">
  
    
            <div className="col-lg-8 ">
             
           
              <div className="bussiness-box  ">
              <div className="  titlepadding">
        <div className="about-author-content  ">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
          
        <h3 className="breadcrumb-item"><a href="Home">Home</a></h3>
              <h3 className="breadcrumb-item"><a href="Football">Football</a></h3>
         
               
            </ol>
          </nav>
        </div>
      </div>
                <div className="section-title">
               
                </div>

                {JsonArray.map(da=>     
  
  <div key= {da.news_title}  > 
 
                <div className="bussiness-items">
                  <div className="bussiness-post-item">
                    <div className="bussiness-post-thumb">
                      <a href={da.link}>
                      <img className='rounded' style={{ maxWidth: '350px',maxHeight:'250px'}}     src={da.Image} alt="business" />
                      </a>
                    </div>
                    <div className="bussiness-post-content">
                      <h3 className="title"><a href={da.link}> {da.news_title}</a></h3>
                      <div className="meta-date-link">
                        <span>{da.news_date}</span>
                      
                      </div>
                      <p> {parse(da.Description)} </p>
             
                    </div>
                  </div>
              
             
               
                 
                
                </div>
                </div>
  )}
 
              </div>
              <br></br>
   
            </div>
            <div className="col-lg-4   ">
              <div className="all-post-sidebar-style-2   ">
            {/* most view */}
            <MostViewed/>
                <div className="ad mt-40">
                  <a href="#"><img src="./images/ad/ad-3.png" alt="" /></a>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
         </>
        );
    }


  
