import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios, { Axios } from "axios";
import parse from "html-react-parser";
import { Link, json, useNavigate } from "react-router-dom";
import { variables } from "./Variables.js";
import { FALSE } from "sass";

export default function FootballMatches() {
  const jsonUrl =
    "https://menafn.com/updates/index/Athlethixnet_HomeEn_Matches.json";

  const [isLoading, setNisLoading] = useState(false);
  const navigate = useNavigate();
  const usenavigate = useNavigate();

  async function refda() {
    //  To Show or Hidden Header And Footer
    document.getElementById("English_Header").hidden = false;
    document.getElementById("English_Footer").hidden = false;
    //
    setNisLoading(true);

    Get_Data();
  }

  const [JsonArray1, setJsonArray1] = React.useState([]);
  const [JsonArray2, setJsonArray2] = React.useState([]);
  const [JsonArray3, setJsonArray3] = React.useState([]);
  const [JsonArray4, setJsonArray4] = React.useState([]);
  const [JsonArray5, setJsonArray5] = React.useState([]);
  const [JsonArray6, setJsonArray6] = React.useState([]);

  // Another Method to fetch json
  const [JsonArray7, setJsonArray7] = React.useState([]);

  async function Get_Data() {
    setNisLoading(true);

    axios.get(jsonUrl).then(function (res) {
      setNisLoading(false);

 
      for (let i in res.data) {
          if (
          res.data[i].SLT_name_en &&
          res.data[i].SLT_name_en.includes("Premier League")
        ) {
          JsonArray1.push({
            SLT_name_en: res.data[i].SLT_name_en,
            Team1: res.data[i].Team1,
            Team2: res.data[i].Team2,
            SM_score1: res.data[i].SM_score1,
            SM_score2: res.data[i].SM_score2,
            SM_game_date_time: res.data[i].SM_game_date_time,
          });
        }
      }
    });

    axios.get(jsonUrl).then(function (res) {
      setNisLoading(false);

 
      for (let i in res.data) {
        if (
          res.data[i].SLT_name_en &&
          res.data[i].SLT_name_en.includes("Champions League ")
        ) {
          JsonArray2.push({
            SLT_name_en: res.data[i].SLT_name_en,
            Team1: res.data[i].Team1,
            Team2: res.data[i].Team2,
            SM_score1: res.data[i].SM_score1,
            SM_score2: res.data[i].SM_score2,
            SM_game_date_time: res.data[i].SM_game_date_time,
          });
        }
      }
    });

    axios.get(jsonUrl).then(function (res) {
      setNisLoading(false);

 
      for (let i in res.data) {
        if (
          res.data[i].SLT_name_en &&
          res.data[i].SLT_name_en.includes("Serie A ")
        ) {
          JsonArray3.push({
            SLT_name_en: res.data[i].SLT_name_en,
            Team1: res.data[i].Team1,
            Team2: res.data[i].Team2,
            SM_score1: res.data[i].SM_score1,
            SM_score2: res.data[i].SM_score2,
            SM_game_date_time: res.data[i].SM_game_date_time,
          });
        }
      }
    });

    axios.get(jsonUrl).then(function (res) {
      setNisLoading(false);

       

      for (let i in res.data) {
        if (
          res.data[i].SLT_name_en &&
          res.data[i].SLT_name_en.includes("Europa League ")
        ) {
          JsonArray4.push({
            SLT_name_en: res.data[i].SLT_name_en,
            Team1: res.data[i].Team1,
            Team2: res.data[i].Team2,
            SM_score1: res.data[i].SM_score1,
            SM_score2: res.data[i].SM_score2,
            SM_game_date_time: res.data[i].SM_game_date_time,
          });
        }
      }
    });

    axios.get(jsonUrl).then(function (res) {
      setNisLoading(false);

  

      for (let i in res.data) {
        if (
          res.data[i].SLT_name_en &&
          res.data[i].SLT_name_en.includes("Bundesliga")
        ) {
          JsonArray5.push({
            SLT_name_en: res.data[i].SLT_name_en,
            Team1: res.data[i].Team1,
            Team2: res.data[i].Team2,
            SM_score1: res.data[i].SM_score1,
            SM_score2: res.data[i].SM_score2,
            SM_game_date_time: res.data[i].SM_game_date_time,
          });
        }
      }
    });

    axios.get(jsonUrl).then(function (res) {
      setNisLoading(false);
 

      for (let i in res.data) {
        if (
          res.data[i].SLT_name_en &&
          res.data[i].SLT_name_en.includes("Ligue 1")
        ) {
          JsonArray6.push({
            SLT_name_en: res.data[i].SLT_name_en,
            Team1: res.data[i].Team1,
            Team2: res.data[i].Team2,
            SM_score1: res.data[i].SM_score1,
            SM_score2: res.data[i].SM_score2,
            SM_game_date_time: res.data[i].SM_game_date_time,
          });
        }
      }
    });

    // axios.get(jsonUrl).then(function (res) {
    //   setNisLoading(false);

    //   console.log(res.data);

    //   for (let i in res.data) {
    //     for (let j in res.data[i]) {
    //       console.log("res.data[i]")
    //       console.log(res.data[i].SLT_name_en)
    //       if(res.data[i].SLT_name_en && res.data[i].SLT_name_en.includes("La Liga ")){
    //       JsonArray7.push({
    //         SLT_name_en:res.data[i].SLT_name_en,
    //         Team1: res.data[i].Team1,
    //         Team2: res.data[i].Team2,
    //         SM_score1: res.data[i].SM_score1,
    //         SM_score2: res.data[i].SM_score2,
    //         SM_game_date_time: res.data[i].SM_game_date_time,
    //       });
    //     }

    //     }
    //   }

    // });
  }

  useEffect(() => {
    setNisLoading(true);

    refda();
  }, []);

  // New Structure for (La Liga) Fill Using fetech to improve Performance.

  

  let index = 0;

  const [Input, setInput] = useState("");

  const fetchdata = (value) => {
    fetch(
      "https://menafn.com/updates/index/Athlethixnet_HomeEn_Matches.json"
    ).then((response) =>
      response.json().then((json) => {
        const result = json.filter((user)=>{
            return user && user.SLT_name_en && user.SLT_name_en.toLowerCase().includes(value),
            user && user.Team1 && user.Team1.toLowerCase().includes(value)
        })
        console.log("Search Result")
        console.log(result)
      })
    );
  };

  const HandleChange = (value) => {
    setInput(value);
    fetchdata(value);
  };
  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <form class="form-inline my-2 my-lg-0">
            <input
              value={Input}
              onChange={(e) => HandleChange(e.target.value)}
              class="form-control mr-sm-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button class="btn btn-outline-success my-2 my-sm-0" type="submit">
              Search
            </button>
          </form>
        </div>
      </nav>{" "}
    </>
  );
}
