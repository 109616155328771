import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';


 
 
export default function TennisMain() {

   


 

    const jsonUrlVideos = variables.basename_url +'updates/index/Athlethixnet_HomeEn_Tennis.json';

    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
   
  
  
      async function refda() {  
 
      
        Get_Data_main_trending();
      }

       

   
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )


          
         
 
 
  
            
  
            
         
           

const [JsonVideos_MainArray, setJsonVideos_MainArray] = React.useState([]);         
   async function Get_Data_main_trending(){
 
    JsonVideos_MainArray.splice(0);  //to clear All data in your page

   setNisLoading(true);

   axios.get(jsonUrlVideos)  

         .then(function (response) {
         setNisLoading( false);

           console.log(response.data);
       
          
         
 
         for ( let i = 0; i < 1 && i < response.data.length; i++) {
         
            JsonVideos_MainArray.push({
            news_title: response.data[i].news_title,
            news_date:response.data[i].news_date.split(" ")[0],
            Image: response.data[i].Image,
            link:response.data[i].link,
            Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  ,
            Category:response.data[i].Category
          
          })
          }
       
    })      
   }
     
 


            return (
          <>
 
            <div className="col-lg-6">
              <div className="video-play-item">
              {JsonVideos_MainArray.map(VMain=>     
  
  <div key= {VMain.news_title}  >  
                <div className="video-play-thumb">
                   
                  <a  href={ VMain.link }>
                            <img style={{ maxWidth: 500 ,maxHeight:250}} src={VMain.Image} alt="gallery" />
                    </a>
                  
                  <div className="share-item">
                  
                  </div>
                  <div className="post-meta">
                    <div className="meta-categories">
                      {/* <a href={ VMain.link } tabIndex={0}  >
                        <p className='colorForCategory'>  {VMain.Category}</p>
                       
                        
                        </a> */}
                    </div>
              
                  
                  </div>
                </div>
                <div className="video-play-content">
                  <h3 className="title"><a  href={  VMain.link }>{VMain.news_title}</a></h3>
                  
                  <div className="meta-post-categores">
                        <a  href={ VMain.link } tabIndex={-1}>{VMain.Category}</a>
                        <a  href={ VMain.link } className='ml-5' tabIndex={-1}>{VMain.news_date}</a>
                      </div>
                      <br></br>
                  <a href={  VMain.link }>{parse(VMain.Description)}</a>
                  
                </div>
              </div>
              )}
            </div>
            </div>
       
  
            
                
    
         </>
        );
    }


  
