import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';


 
 
export default function BasketballHome() {

    const jsonbasket = variables.basename_url +'updates/index/Athlethixnet_HomeEn_Basketball.json';


  


    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
   
  
  
      async function refda() {  
 
        Get_Data_basket()
       
      }

      
           
   const [JsonBasketArray, setJsonBasketArray] = React.useState([]);
   async function Get_Data_basket(){
 
    JsonBasketArray.splice(0);  //to clear All data in your page

   setNisLoading(true);

   axios.get(jsonbasket)  

         .then(function (response) {
         setNisLoading( false);

           console.log(response.data);
       
          
     

         for (let i = 0; i <= 2 && i < response.data.length; i++) {
         
          JsonBasketArray.push({
        
            news_title: response.data[i].news_title,
            news_date:response.data[i].news_date.split(" ")[0],
            Image: response.data[i].Image,
                        link:response.data[i].link,
            Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  ,
            Category:response.data[i].Category,
          
          })
          }
    })      
   }
     

   
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )


          
         
 
 

 


            return (
          <>

<div className="finance-box mt-40">
              <div className="section-title">
                <a href='BasketBall'>
                <h3 className="title">Basketball News</h3>
                </a>
              </div>
              <div className="finance-items">
             
              {JsonBasketArray.map(da=>     
  
  <div key= {da.news_title}  > 
                    
                <div className="finance-item">
                  <div className="finance-thumb">
                   
                    <a  href={ da.link }>
                    <img className='BaskteImg' src={da.Image} alt="gallery" />
                    </a>

                    <div class="finance-date">
                                            <span>{da.news_date}</span>
                                        </div>

                  </div>
                  <div className="finance-content">
                    <h3 className="title"><a href={ da.link }>{da.news_title}</a></h3>
                   <p>{parse(da.Description)}</p>
           
                  </div>
                </div>
           
              </div>
              )}
                </div>
            </div>
 
 
         </>
        );
    }


  
