import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios, { Axios } from "axios";
import parse from "html-react-parser";
import { Link, json, useNavigate } from "react-router-dom";
import { variables } from "./Variables.js";
import { FALSE } from "sass";
import tr from "date-fns/esm/locale/tr/index.js";

export default function AllResultMatcehs() {
    const jsonUrl = "https://menafn.com/updates/index/Athlethixnet_HomeEn_Matches.json";


    const [LeagueName, setLeagueName] = React.useState([]);


    const [isLoading, setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate = useNavigate();




    //  Small Api Can Display All Matches And Result in My Page 


    useEffect(() => {
        fetch(jsonUrl)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setLeagueName(data);
            })

            .catch(error => {
                console.log('Error fetching')
            })


    })



    const [leagueData, setLeagueData] = useState({}); //Declare Array to fill data from json 
    const [isloading, setIsloading] = useState(true);

    useEffect(() => {
        Display_All_Matcehs();
        // To Can Show A Header
        document.getElementById("English_Header").hidden = false;
        document.getElementById("English_Footer").hidden = false;
        //

        
       

    }, []);




    //To Can Change Background-color 
    let index = 1;

    const [AllMatchesTitle, setAllMatchesTitle] = React.useState([]);
    const [AllMatches, setAllMatches] = React.useState([]);


    const { SLT_id } = useQueryParams();

    function useQueryParams() {
        const params = new URLSearchParams(
            window ? window.location.search : {}
        );

        return new Proxy(params, {
            get(target, prop) {
                return target.get(prop)
            },
        });
    }

 

    async function Display_All_Matcehs() {
        setNisLoading(true);
        let jwttoken = Cookies.get('jwttoken');

        axios.get(variables.API_URL + '/external_site/Fill_AllMatches', {

            headers: { 'Authorization': 'bearer ' + jwttoken },

            params: {
                IDLeague: SLT_id
            }

        })
            .then(function (response) {
                setNisLoading(false);
                

                for (let i in response.data) {
                    AllMatches.push({
                    
                        Team1: response.data[i].Team1,
                        Team2: response.data[i].Team2,
                        ST_logo: response.data[i].ST_logo,
                        ST_logo1: response.data[i].ST_logo1,
                        SM_game_date_time: response.data[i].SM_game_date_time.replace('T', ' '),
                        SM_score1:response.data[i].SM_score1,
                        SM_score2:response.data[i].SM_score2,
                    })
                 
                }

           
                    AllMatchesTitle.push({
                        SLT_name_en: response.data[0].SLT_name_en,
                        SLT_Logo: response.data[0].SLT_Logo,
 

                    })
                 
            })
    }

 


    return (
        <>






            <div className="container custom-container ">

                <div className="feature-news-content mt-3">
                    <p className="TitleSectionFont p-1 bg-white ">
                        Welcome to AthletixNet Football Matches! Find all the latest football match schedules, live scores, and highlights. We cover all your favorite teams and leagues, so you never miss any action.
                    </p>
                </div>

                {AllMatchesTitle.map(da =>

                    <div key={da.news_title}  >

                        <div
                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 float-left HeaderTable-margin box-title mt-1 "
                            style={{ backgroundColor: index % 2 === 0 ? "white" : "#eceef2" }}
                        >
                            <p hidden>{index++}</p>

                            <div className="d-flex ">
                                <div>
                                    <img
                                        className="Match-logo"
                                        src={variables.MatchesImagePath + da.SLT_Logo}
                                        width={35}
                                        height={35}

                                    />
                                </div>
                                <div className="logoSize ml-3">

                                    {da.SLT_name_en }

                                </div>


                            </div>



                        </div>

                        </div>
                    )}


 

{AllMatches.map(da =>

    <div key={da.news_title}  >
                        <div

                            className="container col-xl-11 col-lg-11 col-md-11 col-sm-11 BodyTable-margin pt-2 d-flex"
                            style={{ backgroundColor: index % 2 === 0 ? "white" : "#eceef2" }}
                        >
                            <p hidden>{index++}</p>
                            <div className="col-md-3 border-0 rounded ml-3">
                                <h6 className="text-center BlueMacthColor">{da.SM_game_date_time}</h6>
                            </div>
                            <div className="col-md-3 BlueMacthColor border-0 rounded text-right">
                                <h6 className="BlueMacthColor">
                                    {da.Team1}
                                    <img
                                        className="ml-3"
                                        src={variables.MatchesImg + da.ST_logo}
                                        width={32}
                                        height={32}
                                        alt={da.Team1}
                                    />
                                </h6>
                            </div>
                            <div className="col-md-2 border-0 rounded">
                                <h6 className="text-center BlueMacthColor">
                                { da.SM_score1 === -1  || da.SM_score1 === null ? "-" : da.SM_score1}<span className="pl-1 pr-1">-</span> { da.SM_score2 === -1  || da.SM_score2 === null ? "-" : da.SM_score2}
                                </h6>
                            </div>
                            <div className="col-md-4 BlueMacthColor border-0 rounded text-left">
                                <h6 className="BlueMacthColor">
                                    <img
                                        className="mr-3"
                                        src={variables.MatchesImg + da.ST_logo1}
                                        width={32}
                                        height={32}
                                        alt={da.Team2}
                                    />
                                    {da.Team2}
                                </h6>
                            </div>
                        </div>
                    </div>
                )}

            </div>





        </>
    );
}
