// Ibs
//   export const variables={        
//   API_URL:"http://199.0.0.98:9999/mgmt_react/API/api" ,
//   MatchesImg:"https://athletixnet.com/Updates/Sports/Teams/"
//  ,basename: '' 

// }


 // LocalHost
// export const variables = {
//   API_URL: "http://localhost:6605/api",
//   ImageStart: "https://athletixnet.com/updates/pr/",
//   MatchesImg:"https://athletixnet.com/Updates/Sports/Teams/",
//   basename: "",
//   basename_url: "https://menafn.com/",
//   domain_name: "http://menafn.com",
//   domain: "Menafn",
//    MatchesImagePath:"https://athletixnet.com/updates/sports/leagues/",
// };


//Online
export const variables={

API_URL:"https://athletixnet.com/mgmt_react/API/api",
ImageStart: "https://athletixnet.com/updates/pr/",
MatchesImg:"https://athletixnet.com/Updates/Sports/Teams/",
MatchesImagePath:"https://athletixnet.com/updates/sports/leagues/",
basename: '',
basename_url: 'https://athletixnet.com/',
domain_name: 'https://athletixnet.com',
domain: 'athletixnet'

    }
