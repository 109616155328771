 
 

import { Routes, Route } from 'react-router-dom';
import {variables} from './Component/Variables';
 
import { useEffect } from "react"
 

 
// Athletex page 
import Home from './Component/Home';
import News_Page from './Component/News_Page';
 
import TrendingNews from './Component/TrendingNews';
import Football from './Component/Football';
import BasketBall from './Component/BasketBall';
import Cricket from './Component/Cricket';
import Tennis from './Component/Tennis';
import Boxing from './Component/Boxing';
 import MostViwed from './Component/MostViwed';

import BasketballHome from './Component/BasketballHome';
import CricketHome from './Component/CricketHome';
import WhatsnewHome from './Component/WhatsnewHome';
import TennisHome from './Component/TennisHome';
import UFCHome from './Component/UFCHome';
import BoxingHome from './Component/BoxingHome';
import TrendingNewsMain from './Component/TrendingNewsMain';
import TennisMain from './Component/TennisMain';
import FeatureNews_Home from './Component/FeatureNews_Home';
import OurLatest_News from './Component/OurLatest_News';
import FootBall_MostViewed from './Component/FootBall_MostViewed'; 
 import UFC from './Component/UFC';
 import Racing from './Component/Racing';
 import FootballMatches from './Component/FootballMatches';
 import SearchBar from './Component/SearchBar';
 import AthletixContactUs from './Component/AthletixContactUs';
  import AthletixAdvertise from './Component/AthletixAdvertise';
 import AthletixPrivacy from './Component/AthletixPrivacy';
 import AthletixAbout from './Component/AthletixAbout';
 import AllResultMatcehs from './Component/AllResultMatcehs';
 import MatchHomePage from './Component/MatchHomePage';
 import TennisMatches from './Component/TennisMatches';
 
 
 
 //
 
 
 

  

export default function App( ) {
   
  return (
    
    
    <div className="App">
  
 

    <Routes>
    <Route path ={ variables.basename + "/TennisMatches"} element={<TennisMatches/>} />
    <Route path ="http://athletixnet.com/TennisMatches" element={<TennisMatches/>} />
    <Route path ={   "/TennisMatches"} element={<TennisMatches/>} />

    <Route path ={ variables.basename + "/MatchHomePage"} element={<MatchHomePage/>} />
    <Route path ="http://athletixnet.com/MatchHomePage" element={<MatchHomePage/>} />
    <Route path ={   "/MatchHomePage"} element={<MatchHomePage/>} />

    <Route path ={ variables.basename + "/AthletixPrivacy"} element={<AthletixPrivacy/>} />
    <Route path ="http://athletixnet.com/AthletixPrivacy" element={<AthletixPrivacy/>} />
    <Route path ={   "/AthletixPrivacy"} element={<AthletixPrivacy/>} />
    
    <Route path ={ variables.basename + "/AthletixAdvertise"} element={<AthletixAdvertise/>} />
    <Route path ="http://athletixnet.com/AthletixAdvertise" element={<AthletixAdvertise/>} />
    <Route path ={   "/AthletixAdvertise"} element={<AthletixAdvertise/>} />
    
    <Route path ={ variables.basename + "/AthletixAbout"} element={<AthletixAbout/>} />
    <Route path ="http://athletixnet.com/AthletixAbout" element={<AthletixAbout/>} />
    <Route path ={   "/AthletixAbout"} element={<AthletixAbout/>} />

 

    <Route path ={ variables.basename + "/AthletixContactUs"} element={<AthletixContactUs/>} />
    <Route path ="http://athletixnet.com/AthletixContactUs" element={<AthletixContactUs/>} />
    <Route path ={   "/AthletixContactUs"} element={<AthletixContactUs/>} />

    <Route path ={ variables.basename + "/SearchBar"} element={<SearchBar/>} />
    <Route path ="http://athletixnet.com/SearchBar" element={<SearchBar/>} />
    <Route path ={   "/SearchBar"} element={<SearchBar/>} />


    
    <Route path ={ variables.basename + "/AllResultMatcehs"} element={<AllResultMatcehs/>} />
    <Route path ="http://athletixnet.com/AllResultMatcehs" element={<AllResultMatcehs/>} />
    <Route path ={   "/AllResultMatcehs"} element={<AllResultMatcehs/>} />

    <Route path ={ variables.basename + "/FootballMatches"} element={<FootballMatches/>} />
    <Route path ="http://athletixnet.com/FootballMatches" element={<FootballMatches/>} />
    <Route path ={   "/FootballMatches"} element={<FootballMatches/>} />

    <Route path ={ variables.basename + "/Football"} element={<Football/>} />
    <Route path ="http://athletixnet.com/Football" element={<Football/>} />
    <Route path ={   "/Football"} element={<Football/>} />

    <Route path ={ variables.basename + "/BasketBall"} element={<BasketBall/>} />
    <Route path ="http://athletixnet.com/BasketBall" element={<BasketBall/>} />
    <Route path ={   "/BasketBall"} element={<BasketBall/>} />

    
    <Route path ={ variables.basename + "/Cricket"} element={<Cricket/>} />
    <Route path ="http://athletixnet.com/Cricket" element={<Cricket/>} />
    <Route path ={   "/Cricket"} element={<Cricket/>} />

 


    <Route path ={ variables.basename + "/Tennis"} element={<Tennis/>} />
    <Route path ="http://athletixnet.com/Cricket" element={<Tennis/>} />
    <Route path ={   "/Tennis"} element={<Tennis/>} />

    


    <Route path ={ variables.basename + "/Boxing"} element={<Boxing/>} />
    <Route path ="http://athletixnet.com/Boxing" element={<Boxing/>} />
    <Route path ={   "/Boxing"} element={<Boxing/>} />

    
    <Route path ={ variables.basename + "/UFC"} element={<UFC/>} /> 
    <Route path ="http://athletixnet.com/UFC" element={<UFC/>} />
    <Route path ={   "/UFC"} element={<UFC/>} />

    
    <Route path ={ variables.basename + "/Racing"} element={<Racing/>} /> 
    <Route path ="http://athletixnet.com/Racing" element={<Racing/>} />
    <Route path ={   "/Racing"} element={<Racing/>} />
     
    
 
    <Route path ={ variables.basename + "/TrendingNews"} element={<TrendingNews/>} /> 
    <Route path ="http://athletixnet.com/TrendingNews" element={<TrendingNews/>} />
    <Route path ={   "/TrendingNews"} element={<TrendingNews/>} />
 

    <Route path ={ variables.basename + "/CricketHome"} element={<CricketHome/>} /> 
    <Route path ="http://athletixnet.com/CricketHome" element={<CricketHome/>} />
    <Route path ={   "/CricketHome"} element={<CricketHome/>} />
 

    <Route path ={ variables.basename + "/BasketballHome"} element={<BasketballHome/>} /> 
    <Route path ="http://athletixnet.com/BasketballHome" element={<BasketballHome/>} />
    <Route path ={   "/BasketballHome"} element={<BasketballHome/>} />

    
    <Route path ={ variables.basename + "/WhatsnewHome"} element={<WhatsnewHome/>} /> 
    <Route path ="http://athletixnet.com/WhatsnewHome" element={<WhatsnewHome/>} />
    <Route path ={   "/WhatsnewHome"} element={<WhatsnewHome/>} />



    
    <Route path ={ variables.basename + "/TennisHome"} element={<TennisHome/>} /> 
    <Route path ="http://athletixnet.com/TennisHome" element={<TennisHome/>} />
    <Route path ={   "/TennisHome"} element={<TennisHome/>} />

    
    
    <Route path ={ variables.basename + "/UFCHome"} element={<UFCHome/>} /> 
    <Route path ="http://athletixnet.com/UFCHome" element={<UFCHome/>} />
    <Route path ={   "/UFCHome"} element={<UFCHome/>} /> 


    <Route path ={ variables.basename + "/BoxingHome"} element={<BoxingHome/>} /> 
    <Route path ="http://athletixnet.com/BoxingHome" element={<BoxingHome/>} />
    <Route path ={   "/BoxingHome"} element={<BoxingHome/>} /> 
     
     
    <Route path ={ variables.basename + "/TrendingNewsMain"} element={<TrendingNewsMain/>} /> 
    <Route path ="http://athletixnet.com/TrendingNewsMain" element={<TrendingNewsMain/>} />
    <Route path ={   "/TrendingNewsMain"} element={<TrendingNewsMain/>} /> 


    <Route path ={ variables.basename + "/TennisMain"} element={<TennisMain/>} /> 
    <Route path ="http://athletixnet.com/TennisMain" element={<TennisMain/>} />
    <Route path ={   "/TennisMain"} element={<TennisMain/>} /> 
    
    <Route path ={ variables.basename + "/FeatureNews_Home"} element={<FeatureNews_Home/>} /> 
    <Route path ="http://athletixnet.com/FeatureNews_Home" element={<FeatureNews_Home/>} />
    <Route path ={   "/FeatureNews_Home"} element={<FeatureNews_Home/>} /> 
    
    <Route path ={ variables.basename + "/OurLatest_News"} element={<OurLatest_News/>} /> 
    <Route path ="http://athletixnet.com/OurLatest_News" element={<OurLatest_News/>} />
    <Route path ={   "/OurLatest_News"} element={<OurLatest_News/>} /> 
    
    
   
    

    <Route path ={ variables.basename + "/FootBall_MostViewed"} element={<FootBall_MostViewed/>} /> 
    <Route path ="http://athletixnet.com/FootBall_MostViewed" element={<FootBall_MostViewed/>} />
    <Route path ={   "/FootBall_MostViewed"} element={<FootBall_MostViewed/>} /> 
    
    
    <Route path ="*" element={<Home/>} />
    <Route path ="" element={<Home/>} />
    <Route path ="/" element={<Home/>} />
    <Route path ="http://athletixnet.com" element={<Home/>} />
    <Route path=  {  "/:storyID/:title"}    element={<News_Page/>} />
 <Route path=  { variables.basename + "/:storyID/:title"}    element={<News_Page/>} />
 <Route path=  { variables.basename + "/news_page/:storyID/:title"}    element={<News_Page/>} />
        
 <Route path=  {   "/:storyID/:title"}    element={<News_Page/>} />

 
 
        <Route path ={ variables.basename + "/Home"} element={<Home/>} />

        <Route path ={ variables.basename + "/News_Page"} element={<News_Page/>} />
        
   
 
        <Route path ={ variables.basename + "/MostViwed"} element={<MostViwed/>} />

 
    
    </Routes>
    </div>

    
    
  );
}
