import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';
import MostViewed from './MostViwed.js';

 
 
export default function BoxingHome() {

 
   

    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
   


    const jsonUrlBoxing = variables.basename_url +'updates/index/Athlethixnet_HomeEn_Boxing.json';

        
      async function refda() {  
        setNisLoading(true);
        Get_Data_Boxing();
       
      }
   


 
      
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )


          
         
 


           const [BoxingArray, setBoxingArray] = React.useState([]);

           async function Get_Data_Boxing(){
          
            BoxingArray.splice(0);  //to clear All data in your page
         
            setNisLoading(true);
         
            axios.get(jsonUrlBoxing)  
         
                  .then(function (response) {
                  setNisLoading( false);
         
                    console.log(response.data);
                
                   
              
         
                  for (let i = 0; i < 1 && i < response.data.length; i++) {
                  
                    BoxingArray.push({
                     news_title: response.data[i].news_title,
                     news_date:response.data[i].news_date.split(" ")[0],
                     Image: response.data[i].Image,
                     link:response.data[i].link,
                     Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  ,
                     Category:response.data[i].Category
        
                   })
                   }
             })      
            }
         
         

         

 

 
     
         

     
            return (
          <>


<div className="opinion-post mt-40">
{BoxingArray.map(boxing=>     
  
  <div key= {boxing.news_title}  > 
        <div className="section-title">
   <a href="Boxing">        <h3  className="title">Boxing</h3></a>
        </div>
        <div className="opinion-post-item">
        <div className="finance-thumb">
                   
                   <a  href={ boxing.link }>
                   <img className='BaskteImg rounded' style={{   maxHeight: '300px'  }} src={boxing.Image} alt="gallery" />
                   </a>

                   <div class="finance-date">
                                         
                                       </div>

                 </div>
          <div className="opinion-post-content">
            <h3 className="title mt-3"><a href={ boxing.link }>{boxing.news_title}</a></h3>
            <p>   {parse(boxing.Description)}</p>
            <div className="meta-post-2-style">
              <div className="meta-post-categores">
                <a href={ boxing.link }>{boxing.Category}</a>
              </div>
              <div className="meta-post-date">
                <span>{boxing.news_date}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
       )}
</div>

         </>
        );
    }


  
