import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';


 
 
export default function FootBall_MostViewed() {

  const jsonUrl = variables.basename_url +'updates/index/Athlethixnet_HomeEn_Football.json';


    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
   
  
  
      async function refda() {  
 
        
        Get_Data_FMostViewed();
      };

   
 
    
      const [FootBall_MostViewed, setFootBall_MostViewed] = React.useState([]);
      
   
   async function Get_Data_FMostViewed(){
 
    FootBall_MostViewed.splice(0);  //to clear All data in your page

  
   setNisLoading(true);

   axios.get(jsonUrl)  

         .then(function (response) {

          setNisLoading( false);

           console.log(response.data);
  
         for (let i = 0; i <= 4 && i < response.data.length; i++) {
         
            FootBall_MostViewed.push({
        
            news_title: response.data[i].news_title,
            news_date:response.data[i].news_date.split(" ")[0],
            Image: response.data[i].Image,
                        link:response.data[i].link,
            Category:response.data[i].Category,
            Description:response.data[i].Description,
          })
          }
    })      
   }
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )


            return (
          <>


  
 <div className="most-view-box mt-2">
        <div className="section-title">
          <a href='Football'> 
          <h3 className="title"> Football  </h3>
          </a>
        </div>
        <div className="trending-sidebar-slider slick-initialized slick-slider"> 
          <div className="slick-list draggable"><div className="slick-track"  >
            <div className="post_gallery_items slick-slide slick-cloned" data-slick-index={-1} aria-hidden="true" tabIndex={-1}  >
        
              
            
        
      
              </div>
              <div className="post_gallery_items slick-slide slick-current slick-active" data-slick-index={0} aria-hidden="false" tabIndex={0} >
            
  
      
       
 {FootBall_MostViewed.map(da=>     
  
  <div key= {da.news_title}  >   
 <div className="post-gallery-style-2 most-view-style-2">
                  <div className="post-gallery-thumb  ">
                  <a href={da.link}>
                    <img className='rounded'  style={{ maxWidth: '85px',minWidth:'85px', maxHeight: '57px', minHeight: '57px' }} src={da.Image}  alt="gallery" />
               </a>
                  </div>
                  <div className="post-gallery-content pl-4">
                    <h5 className="title"><a href={da.link }    tabIndex={-1}>{da.news_title}</a></h5>
                    <div className="meta-post-2-style">
                      <div className="meta-post-categores">
                        <a href="#" tabIndex={-1}>{da.Category}</a>
                      </div>
                      <div className="meta-post-date">
                        <span>{da.news_date}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Start line between news */}
             <div></div>
               {/* End line between news */}
                </div>
                 
               )}
              
       
              </div>


              
              {/* <div className="post_gallery_items slick-slide" data-slick-index={1} aria-hidden="true" tabIndex={-1} style={{width: '300px'}}>
    
           
           
 {FootBall_MostViewed.map(da=>     
  
  <div key= {da.news_title}  >   
 <div className="post-gallery-style-2 most-view-style-2">
                  <div className="post-gallery-thumb">
                    <img  style={{ maxWidth: '83px', maxHeight: '57px' }} src={da.news_imagename}  alt="gallery" />
                  
                  </div>
                  <div className="post-gallery-content">
                    <h5 className="title"><a href={da.link } tabIndex={-1}>{da.news_title}</a></h5>
                    <div className="meta-post-2-style">
                      <div className="meta-post-categores">
                        <a href="#" tabIndex={-1}>{da.Category}</a>
                      </div>
                      <div className="meta-post-date">
                        <span>{da.news_date}</span>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
               )}
           
  
              </div> */}
              {/* <div className="post_gallery_items slick-slide slick-cloned" data-slick-index={2} aria-hidden="true" tabIndex={-1} style={{width: '300px'}}>
        
               
        
 {FootBall_MostViewed.map(da=>     
  
  <div key= {da.news_title}  >   
 <div className="post-gallery-style-2 most-view-style-2">
                  <div className="post-gallery-thumb">
                    <img  style={{ maxWidth: '83px', maxHeight: '57px' }} src={da.news_imagename}  alt="gallery" />
          
                  </div>
                  <div className="post-gallery-content">
                    <h5 className="title"><a href={da.link } tabIndex={-1}>{da.news_title}</a></h5>
                    <div className="meta-post-2-style">
                      <div className="meta-post-categores">
                        <a href="#" tabIndex={-1}>{da.Category}</a>
                      </div>
                      <div className="meta-post-date">
                        <span>{da.news_date}</span>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
               )}
              
        
              </div> */}
              <div className="post_gallery_items slick-slide slick-cloned" data-slick-index={3} aria-hidden="true" tabIndex={-1} style={{width: '300px'}}>
        
         
            
             
            
              </div></div></div>
           </div>
      </div>

 
 
   
  
         </>
        );
    }


  
