import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios, { Axios } from "axios";
import parse from "html-react-parser";
import { Link, json, useNavigate } from "react-router-dom";
import { variables } from "./Variables.js";
import { FALSE } from "sass";
import tr from "date-fns/esm/locale/tr/index.js";

export default function FootballMatches() {
  const jsonUrl = "https://athletixnet.com/updates/index/Athlethixnet_HomeEn_Matches.json";
 
  const [LeagueName, setLeagueName] = React.useState([]);
  const [isLoading, setNisLoading] = useState(false);
  const navigate = useNavigate();
  const usenavigate = useNavigate();

  
//  Small Api Can Display All Matches And Result in My Page 

 
  useEffect(()=>{
    fetch(jsonUrl)
    .then
    (response => response.json()  )
    .then(data => {
      setLeagueName(data);
     })
  
    .catch(error =>{
    console.log('Error fetching')
    })
     
    
     })

 
    
  
  const [leagueData, setLeagueData] = useState({}); //Declare Array to fill data from json 
  const [isloading, setIsloading] = useState(true);

  useEffect(() => {
    // To Can Show A Header
    document.getElementById("English_Header").hidden = false;
    document.getElementById("English_Footer").hidden = false;
    //

    //Function Return Data
    //Must be check when entry data equal SGT_name=football.
    const fetchData = async () => {
      try {
        //1-Fetech Data From Json Url 
        const response = await axios.get(jsonUrl);
        const data = response.data;

        //2-Filter Data Via Sections  (match.SGT_name === "Football")
        const filteredData = data.filter(match => match.SGT_name === "Football");

        //3-Save data After filtering Stage1
        const organizedData = filteredData.reduce((acc, match) => {
          const section = match.SLT_name_en;
          
          
          if (!acc[section]) {
            acc[section] = [];
          }
          acc[section].push(match);
          return acc;
        }, {});
        //4-Save data After filtering Stage2
        setLeagueData(organizedData);
    
        setIsloading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsloading(false);
      }

    };

    //Call Function 
    fetchData();
      
    
  }, []);
 
  //To Can Change Background-color 
   let index = 0;
   
  
  

  return (
    <>

 
 

<div className="container custom-container ">
      <div className="feature-news-content mt-3">
        <p className="TitleSectionFont p-1 bg-white ">
          Welcome to AthletixNet Football Matches! Find all the latest football match schedules, live scores, and highlights. We cover all your favorite teams and leagues, so you never miss any action.
        </p>
      </div>

    <a href="FootballMatches">  
      <button type="button" class=" redbackcolor text-white p-2 rounded border border-white mb-2">Football</button>
      </a>

      <a href="TennisMatches">  
      <button type="button" class=" grayBackground text-white p-2 rounded border border-white mb-2">Tennis</button>
      </a>

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        Object.keys(leagueData).map((section, index) => (
          <div className=" mb-3" key={section}>
            <p hidden>{index++}</p>
            <div
              className="col-xl-12 col-lg-12 col-md-12 col-sm-12 float-left HeaderTable-margin box-title mt-1 "
              style={{ backgroundColor: index % 2 === 0 ? "white" : "#eceef2" }}
            >
                <p hidden>{index++}</p>
              <div className="d-flex">
                <div>
                  <img
                    className="Match-logo"
                    src={`${variables.MatchesImagePath}${leagueData[section][0].SLT_Logo}`}
                    width={35}
                    height={35}
                    alt={section}
                  />
                </div>
                <div className="logoSize ml-3">
                  
                <a href=   {"AllResultMatcehs?SLT_id=" + leagueData[section][0].SLT_id}  target="_blank" class=" text-decoration-none   "  >{section} </a>
                
                </div>


                
                                                    
                                                   
              </div>
            </div>

            {leagueData[section].map((match, idx) => (
              <div
                key={idx}
                className="container col-xl-11 col-lg-11 col-md-11 col-sm-11 BodyTable-margin pt-2 d-flex"
                style={{ backgroundColor: index % 2 === 0 ? "white" : "#eceef2" }}
              >
                <p hidden>{index++}</p>
                <div className="col-md-3 border-0 rounded ml-3">
                  <h6 className="text-center BlueMacthColor">{match.SM_game_date_time}</h6>
                </div>
                <div className="col-md-3 BlueMacthColor border-0 rounded text-right">
                  <h6 className="BlueMacthColor">
                    {match.Team1}
                    <img
                      className="ml-3"
                      src={`${variables.MatchesImg}${match.ST_logo}`}
                      width={32}
                      height={32}
                      alt={match.Team1}
                    />
                  </h6>
                </div>
                <div className="col-md-2 border-0 rounded">
                  <h6 className="text-center BlueMacthColor">
                  {match.SM_score1 === '' || match.SM_score1==='-1'? '-' : match.SM_score1}<span className="pl-1 pr-1">-</span>{match.SM_score2 === '' || match.SM_score2 === '-1'? '-' : match.SM_score2}
                  </h6>
                </div>
                <div className="col-md-4 BlueMacthColor border-0 rounded text-left">
                  <h6 className="BlueMacthColor">
                    <img
                      className="mr-3"
                      src={`${variables.MatchesImg}${match.ST_logo1}`}
                      width={32}
                      height={32}
                      alt={match.Team2}
                    />
                    {match.Team2}
                  </h6>
                </div>
              </div>
            ))}
           
          </div>
          
        ))
      )}
      
 
    </div>
  

 
    </>
  );
}
