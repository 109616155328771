import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';

import FootBall_MostViewed from './FootBall_MostViewed.js'; 
 
export default function AthletixContactUs() {

  const jsonUrl = variables.basename_url +'updates/index/Athlethixnet_HomeEn_BasketBall.json';
 
  
 
    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
     
 
  
    async function refda() {  
                 //  To Show or Hidden Header And Footer
                 document.getElementById('English_Header').hidden = false;
                 document.getElementById('English_Footer').hidden = false;
                 //
      setNisLoading(true);
   
      Get_Data();
     
    }

      const [JsonArray, setJsonArray] = React.useState([]);
 


      async function Get_Data(){
     
        JsonArray.splice(0);  //to clear All data in your page
  
       setNisLoading(true);
    
       axios.get(jsonUrl)  

             .then(function (response) {
             setNisLoading( false);
  
               console.log(response.data);
           
              
         
  
             for (let i in response.data) {
             
              JsonArray.push({
            
                news_title: response.data[i].news_title,
                news_date:response.data[i].news_date,
                news_imagename:variables.ImageStart + response.data[i].news_imagename  ,
                link:response.data[i].link,
                Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  ,

               Category:response.data[i].Category,
              })
              }
        })      
       }
         
    
     
      
      
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )











 










            return (
          <>


 
 


 


 

<section className=" ">
        <div className="container custom-container">
          <div className="   feature-news-content   mt-4  bg-white">
         
          </div>
          <div className="row  ">
            <div className="col-lg-8   ">
              <div className="container custom-container  bg-white">
               {/* here put */}
               <p className="mt-3">
                <h6 className="ABOUTfONT pl-1 pt-2">    Contact Us </h6>
              </p>

  
              <p>  To get in touch, simply fill out the contact form below or email us directly at Athletixnet@gmail.com.  
</p>

<p>We aim to respond to all messages within 24-48 hours. For more immediate assistance, you can also <br></br>
connect with us on our social media channels. Follow us on Twitter, Facebook, and Instagram for real-time <br></br>
updates and to join the conversation with fellow sports enthusiasts.</p>

 <p>Thank you for choosing Athletixnet – where your passion for sports meets our commitment to excellence.</p>

              </div>
              
              
            </div>
            <div className="col-lg-4 mt-1">
              <div className="all-post-sidebar-style-2  ">
               <FootBall_MostViewed/>
            
              
              </div>
            </div>
          </div>
        </div>
      </section>
  
         </>
        );
    }


  
