import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';


 
 
export default function TrendingNewsMain() {

  const TrendingUrlMain =variables.basename_url +'updates/index/Athlethixnet_HomeEn_TrendingNews.json';

    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
   
  
    async function refda() {  
        setNisLoading(true);
     
       
        Get_Data_main_trending();
      }

    

       const [JsonTrending_MainArray, setJsonTrending_MainArray] = React.useState([]);
       async function Get_Data_main_trending(){
     
        JsonTrending_MainArray.splice(0);  //to clear All data in your page
    
       setNisLoading(true);
    
       axios.get(TrendingUrlMain)  
    
             .then(function (response) {
             setNisLoading( false);
    
               console.log(response.data);
           
              
             
     
             for ( let i = 0; i < 1 && i < response.data.length; i++) {
             
              JsonTrending_MainArray.push({
                news_title: response.data[i].news_title,
                news_date:response.data[i].news_date.split(" ")[0],
                Image: response.data[i].Image,
                                link:response.data[i].link,
                Category:response.data[i].Category,
                 Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  

              })
              }
           
        })      
       }

   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )


 //End Most Part

            return (
          <>

              <div className="col-md-6">
                <div className="post-news-item-2">
                {JsonTrending_MainArray.map(Main=>     
  
  <div key= {Main.news_title}  >  
                  <div className="post-news-thumb">
                  
                    <a  href={ Main.link }>
                    <img  className='rounded' style={{ maxWidth: '335px', maxHeight: '219px' , minWidth: '335px', minHeight: '219px' }} src={Main.Image} alt="gallery" />
                    </a>
                  </div> 
                  <div className="post-news-content">
                    <h3 className="title "><a href={ Main.link } >{Main.news_title}</a></h3>
                    <p className='Redcolorhover'> {parse(Main.Description)}  </p>
                    <div className="meta-post-2-style">
                      <div className="meta-post-categores">
                        <a href={ Main.link }className='colorForCategory'>{Main.Category}</a>
                      </div>
                      <div className="meta-post-date">
                        <span>{Main.news_date}</span>
                      </div>
                    </div>
                  </div>
                </div>
                 
)}
         </div>
              </div>
              


   
        
 
         </>
        );
    }


  
