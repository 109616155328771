import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';
import TrendingNewsMain from './TrendingNewsMain.js';
 

export default function TrendingNews() {
 
 
  const TrendingUrl2 =variables.basename_url +'updates/index/Athlethixnet_HomeEn_TrendingNews.json';
  


    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
   
  
    async function refda() {  
        setNisLoading(true);
     
        Get_Data();
       
      }

      const [JsonTrendingArray, setJsonTrendingArray] = React.useState([]);
 
      async function Get_Data(){
     
        JsonTrendingArray.splice(0);  //to clear All data in your page
  
       setNisLoading(true);
    

       axios.get(TrendingUrl2   )

   

             .then(function (response) {
             setNisLoading( false);
  
            
           
              
              
             console.log(response.data);
             for ( let i = 1; i < 6 && i < response.data.length; i++) {
             
              
              console.log('laith');
              console.log(response.data);
                JsonTrendingArray.push({
                  news_industryIDs: response.data[i].news_industryIDs,
                  Image: response.data[i].Image,
                    Title: response.data[i].Title,
                    Description: response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain),
                    link:response.data[i].link,     
                     news_date:response.data[i].news_date.split(" ")[0],
                     Category:response.data[i].Category,
              })
              }
        })      
       }
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
  

        refda();
           },[] )



            return (
          <>


<div className="container custom-container  bg-white">
        <div className="video-style-box  mt-2">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <a href='Tennis_Ar'> 
                <h3 className="title   mt-4 mr-4 mb-1">Trending News      </h3>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
       {/* main post o tennis */}
       <TrendingNewsMain/>
            <div className="col-lg-6">
            {JsonTrendingArray.map(da=>     
  
  <div key= {da.Title}  > 
              <div className="video-play-sidebar-items">
                <div className="post-gallery-style-2">
                  <div className="post-gallery-thumb">
                  <a  href={ da.link }>
                
                            <img className='rounded'    style={{ maxWidth: '100px', maxHeight: '70px' , minWidth: '100px', minHeight: '70px' }}  src={da.Image} alt="gallery" />
                    </a>
                  </div>
                  <div className="post-gallery-content col-sm-8">
                    <h5 className="title"><a href={ da.link } tabIndex={-1}>{da.Title}</a></h5>
                    <div className="meta-post-2-style">
                    <div class="meta-post-2-style">
                 
                                                <div class="meta-post-categores">
                                                    <a href={da.link}tabindex="-1">{da.Category}</a>
                                                </div>
                                                <div class="meta-post-date">
                                                    <span className=''>{da.news_date}</span>
                                                </div>
                                               
                                            </div>
                    
                    </div>
                  </div>
                </div>
               
          <div ></div>
          
           
     
              </div>
         
            </div>
       
            )}
 </div>
 
          </div>
        </div>
      </div>

  
         </>
        );
    }


  
