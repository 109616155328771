import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';

import MostViewed from './MostViwed.js';
import FootBall_MostViewed from './FootBall_MostViewed.js';

 
export default function AthletixPrivacy() {

  const jsonUrl = variables.basename_url +'updates/index/Athlethixnet_HomeEn_BasketBall.json';
 
  
 
    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
     
 
  
    async function refda() {  
                 //  To Show or Hidden Header And Footer
                 document.getElementById('English_Header').hidden = false;
                 document.getElementById('English_Footer').hidden = false;
                 //
      setNisLoading(true);
   
      Get_Data();
     
    }

      const [JsonArray, setJsonArray] = React.useState([]);
 


      async function Get_Data(){
     
        JsonArray.splice(0);  //to clear All data in your page
  
       setNisLoading(true);
    
       axios.get(jsonUrl)  

             .then(function (response) {
             setNisLoading( false);
  
               console.log(response.data);
           
              
         
  
             for (let i in response.data) {
             
              JsonArray.push({
            
                news_title: response.data[i].news_title,
                news_date:response.data[i].news_date,
                news_imagename:variables.ImageStart + response.data[i].news_imagename  ,
                link:response.data[i].link,
                Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  ,

               Category:response.data[i].Category,
              })
              }
        })      
       }
         
    
     
      
      
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )











 










            return (
          <>


 
 



<section className=" ">
        <div className="container custom-container">
          <div className="   feature-news-content   mt-4  bg-white">
         
          </div>
          <div className="row  ">
            <div className="col-lg-8   ">
              <div className="container custom-container  bg-white">
               {/* here put */}
               <p className="mt-3">
                <h6 className="ABOUTfONT pl-1 pt-2"> Privacy & Policy </h6>
              </p>

              <p>  At Athletixnet, we prioritize your privacy and are committed to protecting your personal information. This Privacy & Policy page outlines how we collect, use, and safeguard the data you provide while using our website. We collect information such as your name, email address, and browsing behavior to enhance your user experience and deliver personalized content. Rest assured, your data is stored securely and will never be shared with third parties without your explicit consent, except as required by law. We utilize industry-standard security measures to protect your information from unauthorized access, alteration, or disclosure.
</p>
 <p> 
 By using Athletixnet, you agree to the terms outlined in this policy. We regularly review and update our privacy practices to ensure they remain effective and aligned with legal requirements. Any changes to this policy will be communicated through our website, ensuring you are always informed about how your information is handled. If you have any questions or concerns regarding our privacy practices, please contact us at  Athletixnet@gmail.com. Your trust is essential to us, and we are dedicated to maintaining the highest standards of privacy and transparency.
</p>

              </div>
              
              
            </div>
            <div className="col-lg-4 mt-1">
              <div className="all-post-sidebar-style-2  ">
               <FootBall_MostViewed/>
            
              
              </div>
            </div>
          </div>
        </div>
      </section>
         </>
        );
    }


  
