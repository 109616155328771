import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';


 
 
export default function CricketHome() {

 

  const jsonCricketUrl =variables.basename_url +'updates/index/Athlethixnet_HomeEn_Cricket.json';
  


    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
   
  
  
      async function refda() {  
 
        Get_Data_Cricket();
       
      }


      const [JsonCricketArray, setJsonCricketArray] = React.useState([]);
      async function Get_Data_Cricket(){
    
       JsonCricketArray.splice(0);  //to clear All data in your page
   
      setNisLoading(true);
   
      axios.get(jsonCricketUrl)  
   
            .then(function (response) {
            setNisLoading( false);
   
              console.log(response.data);
          
             
        
   
            for (let i = 0; i < 4 && i < response.data.length; i++) {
            
             JsonCricketArray.push({
        
               news_title: response.data[i].news_title,
               news_date:response.data[i].news_date.split(" ")[0],
               Image: response.data[i].Image,
                              link:response.data[i].link,
              Category:response.data[i].Category,
              Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  ,
             })
             }
       })      
      }
      
   console.log(JsonCricketArray);
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )


            return (
          <>


<div className="bussiness-box mt-40  bussiness-box">
            <div className="section-title">
              <a href='Cricket'> 
              <h3 className="title">Cricket News</h3>
              </a>
            </div>
            <div className="bussiness-items">
        
            {JsonCricketArray.map(C=>     
  
  <div key= {C.news_title}  > 

              <div className="bussiness-post-item">
                <div className="bussiness-post-thumb">
                  
           

                  <a  href={ C.link }>
                            <img  className='rounded' style={{ maxWidth: '300px', maxHeight: '250px' }} src={C.Image} alt="gallery" />
                    </a>
                </div>
                <div className="bussiness-post-content">
                  <h3 className="title"><a href={ C.link }>{C.news_title}</a></h3>
                  <a  href={ C.news_date } tabIndex={-1}>{C.news_date}</a>
      
                   
                  <div className="mt-1 ">
                     
                 
                      <a  href={ C.Description } tabIndex={-1}>{parse(C.Description)}</a>
                      </div>
                </div>
              </div>
      
      
            </div> 
            
            )}
            </div> 
          </div>
 
 
 
         </>
        );
    }


  
