import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import { variables } from "./Variables.js";
import { FALSE } from 'sass';


 
 
export default function MatchHomePage() {

    const jsonUrl = "https://athletixnet.com/updates/index/Athlethixnet_HomeEn_Matches.json";


    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
   
  
  
      async function refda() {  
 
        
        Get_Data_FMostViewed();
      };

   
 
    
      const [Match_HomePage, setMatch_HomePage] = React.useState([]);
      const [Match_HomePageWithResult, setMatch_HomePageWithResult] = React.useState([]);
      
   
   async function Get_Data_FMostViewed(){
 
    Match_HomePage.splice(0);  //to clear All data in your page

  
   setNisLoading(true);

   axios.get(jsonUrl)  

         .then(function (response) {

          setNisLoading( false);

           console.log(response.data);
  
         for (let i = 0; i <= 2 && i < response.data.length; i++) {
         
            Match_HomePage.push({
        
                SLT_name_en: response.data[i].SLT_name_en,
                SLT_Logo:response.data[i].SLT_Logo ,

                Day: response.data[i].SM_game_date_time.split(' ')[0],
             
                Time1: response.data[i].SM_game_date_time.split(' ')[1],
                Time2: response.data[i].SM_game_date_time.split(' ')[2],

                Team1:response.data[i].Team1,
                Team2:response.data[i].Team2,
                ST_logo:response.data[i].ST_logo,
                ST_logo1:response.data[i].ST_logo1,
                SM_score1:response.data[i].SM_score1,
                SM_score2:response.data[i].SM_score2,

          })
          }
          for (let i = 2; i <=3  && i < response.data.length; i++) {
         
            Match_HomePageWithResult.push({
        
                SLT_name_en: response.data[i].SLT_name_en,
                SLT_Logo:response.data[i].SLT_Logo ,

                Day: response.data[i].SM_game_date_time.split(' ')[0],
             
                Time1: response.data[i].SM_game_date_time.split(' ')[1],
                Time2: response.data[i].SM_game_date_time.split(' ')[2],

                Team1:response.data[i].Team1,
                Team2:response.data[i].Team2,
                ST_logo:response.data[i].ST_logo,
                ST_logo1:response.data[i].ST_logo1,
                SM_score1:response.data[i].SM_score1,
                SM_score2:response.data[i].SM_score2,

          })
          }
    })      
   }
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )


            return (
          <>


 




      {/* break */}
      <div className="upcoming-matches-post mt-35 most-view-box mt-2">
        <div className="section-title d-flex justify-content-between align-items-center">
          <h3 className="title">Latest Matches</h3>
          <a href="FootballMatches"> SEE ALL</a>
        </div>
        <div className="upcoming-matches-post-items">
         
        


        <div className="container mt-5">
        <div className="card match-card">
        {Match_HomePageWithResult.map(da=>    
 
 
 <div key= {da.SLT_name_en}  >  
          <div className="card-body">

 
            <div className="row">
              <div className="col-5 team">
                <img  src={ variables.MatchesImg + da.ST_logo} alt="West Ham United Logo" />
                <span>{da.Team1}</span>
              </div>
              <div className="col-3 d-flex align-items-center justify-content-center">
              <span className='pl-1 pr-1'>{da.SM_score1 === '' || da.SM_score1==='-1'? '-' : da.SM_score1}</span><span>VS</span> <span className='pl-1 pr-1'>{da.SM_score2 === '' || da.SM_score2==='-1'? '-' : da.SM_score2}</span>
              </div>
              <div className="col-4 team">
                <img  src={variables.MatchesImg + da.ST_logo1} alt="Aston Villa Logo" />
                <span>{da.Team2}</span>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <span>{da.Day}</span>
                <span className="  ml-3 timeee"><strong>{da.Time1} {da.Time2}</strong></span>
              </div>
            </div>
          </div>
          </div>
)}
        </div>
      </div>

 

        </div>
      </div>
 
 
   
  
         </>
        );
    }


  
