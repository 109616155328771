import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';

import FootBall_MostViewed from './FootBall_MostViewed.js';

 
 
export default function AthletixAdvertise() {

  const jsonUrl = variables.basename_url +'updates/index/Athlethixnet_HomeEn_BasketBall.json';
 
  
 
    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
     
 
  
    async function refda() {  
                 //  To Show or Hidden Header And Footer
                 document.getElementById('English_Header').hidden = false;
                 document.getElementById('English_Footer').hidden = false;
                 //
      setNisLoading(true);
   
      Get_Data();
     
    }

      const [JsonArray, setJsonArray] = React.useState([]);
 


      async function Get_Data(){
     
        JsonArray.splice(0);  //to clear All data in your page
  
       setNisLoading(true);
    
       axios.get(jsonUrl)  

             .then(function (response) {
             setNisLoading( false);
  
               console.log(response.data);
           
              
         
  
             for (let i in response.data) {
             
              JsonArray.push({
            
                news_title: response.data[i].news_title,
                news_date:response.data[i].news_date,
                news_imagename:variables.ImageStart + response.data[i].news_imagename  ,
                link:response.data[i].link,
                Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  ,

               Category:response.data[i].Category,
              })
              }
        })      
       }
         
    
     
      
      
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )











 










            return (
          <>


   




 

<section className=" ">
        <div className="container custom-container">
          <div className="   feature-news-content   mt-4  bg-white">
         
          </div>
          <div className="row  ">
            <div className="col-lg-8   ">
              <div className="container custom-container  bg-white">
               {/* here put */}
               <p className="mt-3">
                <h6 className="ABOUTfONT pl-1 pt-2">       Advertise with Us </h6>
              </p>

  
              <p>  Reach a passionate and engaged audience by advertising with Athletixnet, the premier destination for sports <br></br> news and analysis. Our readers are avid sports enthusiasts who rely on us for timely updates, in-depth coverage, and expert commentary across a variety of sports. By partnering with Athletixnet, your brand can connect with a diverse and dynamic community, enhancing your visibility and driving targeted engagement. We offer a range of advertising options, including banner ads, sponsored content, and custom campaigns tailored to meet your specific marketing goals.<br></br>
Our advertising team is dedicated to helping you achieve the best results. We provide detailed analytics and reporting to ensure your campaign's success and offer continuous support to optimize performance. Whether you're looking to launch a new product, increase brand awareness, or drive traffic to your website, Athletixnet provides the platform and audience you need to succeed.<br></br> Contact us today at Athletixnet@gmail.com to learn more about our advertising opportunities and how we can help your brand score big with our readers.
</p>

              </div>
              
              
            </div>
            <div className="col-lg-4 mt-1">
              <div className="all-post-sidebar-style-2  ">
               <FootBall_MostViewed/>
            
              
              </div>
            </div>
          </div>
        </div>
      </section>
  
  
         </>
        );
    }


  
