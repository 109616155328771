import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';

import TennisMain from './TennisMain.js';
 
 
export default function TennisHome() {

   


 

    const jsonUrlVideos = variables.basename_url +'updates/index/Athlethixnet_HomeEn_Tennis.json';

    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
   
  
  
      async function refda() {  
 
        Get_Data_Videos();
     
      }

       

   
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )


          
         
 
 
  
           const [JsonVideosArray, setJsonVideosArray] = React.useState([]);
  
       
         
            async function Get_Data_Videos(){
          
             JsonVideosArray.splice(0);  //to clear All data in your page
         
            setNisLoading(true);
         
            axios.get(jsonUrlVideos)  
         
                  .then(function (response) {
                  setNisLoading( false);
         
                    console.log(response.data);
 
              
         
                  for (let i = 1; i <= 6 && i < response.data.length; i++) {
                  
                   JsonVideosArray.push({
                 
                     news_title: response.data[i].news_title,
                     news_date:response.data[i].news_date.split(" ")[0],
                     Image: response.data[i].Image,
                     link:response.data[i].link,
                     
                    Category:response.data[i].Category
                   })
                   }
             })      
            }
         

             
     
 


            return (
          <>
 
 <div className="container custom-container">
        <div className="video-style-box mt-40">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <a href='Tennis'> 
                <h3 className="title">Tennis News</h3>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
       {/* main post o tennis */}
       <TennisMain/>
            <div className="col-lg-6">
            {JsonVideosArray.map(da=>     
  
  <div key= {da.news_title}  > 
              <div className="video-play-sidebar-items">
                <div className="post-gallery-style-2">
                  <div className="post-gallery-thumb">
                  <a  href={ da.link }>
                            <img className='rounded' style={{ maxWidth: 100 ,maxHeight:70}}  src={da.Image} alt="gallery" />
                    </a>
                  </div>
                  <div className="post-gallery-content ">
                    <h5 className="title"><a href={ da.link } tabIndex={-1}>{da.news_title}</a></h5>
                    <div className="meta-post-2-style">
                    <div class="meta-post-2-style">
                                                <div class="meta-post-categores">
                                                    <a href={da.link}tabindex="-1">{da.Category}</a>
                                                </div>
                                                <div class="meta-post-date">
                                                    <span className=''>{da.news_date}</span>
                                                </div>
                                            </div>
                    
                    </div>
                  </div>
                </div>
               
          <div ></div>
          
           
     
              </div>
         
            </div>
       
            )}
 </div>
 
          </div>
        </div>
      </div>


                
    
         </>
        );
    }


  
