import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import { variables } from './Variables.js';
import { FALSE } from 'sass';

import FootBall_MostViewed from './FootBall_MostViewed.js';
import TrendingNews from './TrendingNews.js';
import CricketHome from './CricketHome.js';
import BasketballHome from './BasketballHome.js';
import WhatsnewHome from './WhatsnewHome.js';
import TennisHome from './TennisHome.js';
import UFCHome from './UFCHome.js';
import BoxingHome from './BoxingHome.js';
import FeatureNews_Home from './FeatureNews_Home.js';
import FootballMatches from './FootballMatches.js';
import MatchHomePage from './MatchHomePage.js';

export default function Home() {



  async function refda() {

    //  To Show or Hidden Header And Footer
   
    //
    setNisLoading(true);

  };


  const [isLoading, setNisLoading] = useState(false);
  const navigate = useNavigate();
  const usenavigate = useNavigate();






  useEffect(() => {


    setNisLoading(true);

    refda();
  }, [])




  return (
    <>
 


      <div className='backgroundcolor'>



        <section className=" ">


          <div className="container custom-container">

            <div className='   feature-news-content   mt-4  bg-white'>

              <p className='TitleSectionFont  p-1 '>Welcome to AthletixNet! Your ultimate source for sports news, including updates on the NBA, Premier League, Formula 1, Wimbledon, UFC, and more. Enjoy highlights and expert analysis to follow your favorite sports, teams, and players with our comprehensive coverage.</p>
              </div>


         
            <div className="row  ">

              <div className="col-lg-8  ">

                <TrendingNews />

                <div className='  '>
                  <FeatureNews_Home />
                </div>
                {/* criket */}

                <CricketHome />

              </div>
              <div className="col-lg-4">
                <div className="all-post-sidebar-style-2  ">
                <MatchHomePage />
                  <FootBall_MostViewed />
                  
                  <div className="ad mt-40">
                    <a href="#"><img src="./images/ad/ad-3.png" alt="" /></a>
                  </div>
                  {/* Basket box */}
                  <BasketballHome />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End First Section */}


        {/*Start  whats new and Videos */}
        <section className='container custom-container'>



          <div className='row'>

            <div className="col-lg-4 ">
              {/* WHATS NEW  */}

              <WhatsnewHome />
            </div>


            <div className='col-lg-8 mt-3   bg-white col-sm-12'>
              {/* Tennis Section */}
              <TennisHome />
            </div>


          </div>
          {/* end videos section and whats new  */}

          <div className='row'>

            <div className='col-lg-4 col-sm-12'>
              <div>
                {/* ads in Opinion Section */}
                <img className=' mt-3' src="./images/ad/ad-3.png" />
              </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
              {/* UFC */}
              <UFCHome />
            </div>


            <div className='col-lg-4 col-sm-12'>
              {/* boxing */}


              <BoxingHome />
            </div>

          </div>

          <br></br>
          <br></br>
        </section>
      </div>
      {/* End of back ground of All */}
    </>
  );
}



