import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

import axios, { Axios } from "axios";
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import { variables } from "./Variables.js";
import { FALSE } from "sass";

import FootBall_MostViewed from "./FootBall_MostViewed.js";

export default function AthletixAbout() {
  const [isLoading, setNisLoading] = useState(false);
  const navigate = useNavigate();
  const usenavigate = useNavigate();

  async function refda() {
    //  To Show or Hidden Header And Footer
    document.getElementById("English_Header").hidden = false;
    document.getElementById("English_Footer").hidden = false;
    //
    setNisLoading(true);
  }

  useEffect(() => {
    setNisLoading(true);

    refda();
  }, []);

  return (
    <>
     

      

      <section className=" ">
        <div className="container custom-container">
          <div className="   feature-news-content   mt-4  bg-white">
         
          </div>
          <div className="row  ">
            <div className="col-lg-8   ">
              <div className="container custom-container  bg-white">
               {/* here put */}
               <p className="mt-3">
                <h6 className="ABOUTfONT pl-1 pt-2"> About Us </h6>
              </p>

              <p>
                Athletixnet is a premier sports website dedicated to providing
                the latest sports news, match results, and <br></br>updates on
                recent games from various sports. Catering to a global audience,
                the site offers a user-friendly <br></br>interface that allows
                fans to easily navigate and find information on their favorite
                teams and athletes.
              </p>
              <p>
                One of Athletixnet's key features is its real-time match result
                updates. Covering sports like football, <br></br> basketball,
                and tennis, the website delivers live scores and detailed
                statistics, making it indispensable during <br></br> major
                tournaments and leagues. Additionally, the site offers in-depth
                analysis and post-match reports, <br></br> enhancing the fan
                experience with valuable insights.
              </p>

              <p>
                Beyond match results, Athletixnet covers a wide range of sports
                news, from transfer rumors and injury <br></br> updates to
                player interviews and managerial changes. The news section is
                frequently updated by a team of <br></br> dedicated journalists,
                ensuring that fans receive accurate and timely information.
              </p>
              <p>
                Athletixnet's recent matches section provides comprehensive
                coverage of past games, including summaries, <br></br> key
                moments, and player performances. This feature is perfect for
                fans who missed a match or want to <br></br> revisit the
                highlights. Overall, Athletixnet serves as an essential platform
                for sports enthusiasts, combining <br></br> timely news, live
                updates, and detailed match coverage.
              </p>
              </div>
              
              
            </div>
            <div className="col-lg-4 mt-1">
              <div className="all-post-sidebar-style-2  ">
               <FootBall_MostViewed/>
            
              
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
