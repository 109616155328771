import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';


 
 
export default function UFCHome() {

  

    const jsonUrlUFC = variables.basename_url +'updates/index/Athlethixnet_HomeEn_UFC.json';
  


    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
   
  
  
      async function refda() {  
 
        Get_Data_UFC();
       
      }

      
            
   
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )


          

   const [UFCArray, setUFCArray] = React.useState([]);

   async function Get_Data_UFC(){
  
    UFCArray.splice(0);  //to clear All data in your page
 
    setNisLoading(true);
 
    axios.get(jsonUrlUFC)  
 
          .then(function (response) {
          setNisLoading( false);
 
            console.log(response.data);
        
           
      
 
          for (let i = 0; i < 1 && i < response.data.length; i++) {
          
            UFCArray.push({
         
             news_title: response.data[i].news_title,
             news_date:response.data[i].news_date.split(" ")[0],
             Image: response.data[i].Image,
             link:response.data[i].link,
             Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  ,
            Category:response.data[i].Category
           })
           }
     })      
    }
 
         
 
 

 


            return (
          <>
 

 <div className="opinion-post mt-40">
{UFCArray.map(UFC=>   <div key= {UFC.news_title}  >  
        <div className="section-title">

          <a  href="UFC"><h3 className="title">UFC</h3></a>
           
        </div>
        <div className="opinion-post-item">
           
  
   
        <div className="finance-thumb">
                   
                   <a  href={ UFC.link }>
                   <img className='BaskteImg rounded'  style={{   maxHeight: '300px'  }}  src={UFC.Image} alt="gallery" />
                   </a>

                   <div class="finance-date">
                                         
                                       </div>

                 </div>
          <div className="opinion-post-content">
            <h3 className="title mt-3"><a href={ UFC.link}>{UFC.news_title}</a></h3>
            <p>{parse(UFC.Description)}</p>
            <div className="meta-post-2-style">
              <div className="meta-post-categores">
                <a href={UFC.link}>{UFC.Category}</a>
              </div>
              <div className="meta-post-date">
                <span>{UFC.news_date}</span>
              </div>
            </div>
          </div>
        </div>
   
    
                   
          
        </div>
       
     )}
</div>

 
         </>
        );
    }


  
